import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {
  const [valueToWatch, setValueToWatch] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setValueToWatch(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value]
  );
  return valueToWatch;
};

export default useDebounce;
