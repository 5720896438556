import React, { useEffect, useState } from 'react';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

const Feedbacks = ({ state, onClickToClose }) => {

  const [urlToShare, setUrl] = useState('');

  const isSuccess = state.status === 'success';
  const isError = state.status === 'error';
  const isLoading = state.status === 'loading';

  let errorMessage = 'Código inválido. Verifique se o código de barras é o correto.';

  if (state.code === 'barcodes-already-sent') errorMessage = 'Código de barras já foi utilizado.';
  if (state.code === 'barcodes-max-limit') errorMessage = 'Você só pode enviar quatro códigos de barras por CPF.';
  if (state.code === 'barcodes-reached-limit') errorMessage = 'Código de barras alcançou o limite de doações.';

  useEffect(() => {
    setUrl(window.location.origin + '/compartilhar');
  }, []);

  return (
    <>
      {isLoading && (
        <div style={{ color: 'white' }}>
          Processando...
        </div>
      )}
      {isError && (
        <div className="feedback-boxed">
          <div className="feedback-boxed-icon">
            <svg
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M45 64.9998H55V74.9998H45V64.9998ZM45 24.9998H55V54.9998H45V24.9998ZM49.975 -0.000244141C22.35 -0.000244141 0 22.3748 0 49.9998C0 77.6248 22.35 99.9998 49.975 99.9998C77.6 99.9998 100 77.6248 100 49.9998C100 22.3748 77.6 -0.000244141 49.975 -0.000244141ZM50 89.9998C27.9 89.9998 10 72.0998 10 49.9998C10 27.8998 27.9 9.99976 50 9.99976C72.1 9.99976 90 27.8998 90 49.9998C90 72.0998 72.1 89.9998 50 89.9998Z" fill="#EC3A3A"/>
            </svg>
          </div>
          <div className="v-space-lg" />
          <h3 className="title is-xxlarge is-400">Oops!</h3>
          <div className="v-space" />
          <p className="text">
            {errorMessage}
          </p>
          <div className="v-space-lg" />
          <button className="button is-outlined is-danger is-small" onClick={onClickToClose}>
            <span>Tentar novamente</span>
          </button>
        </div>
      )}
      {isSuccess && (
        <div className="feedback-boxed">
          <h3 className="title is-large is-600">Doação Realizada!</h3>
          <div className="v-space" />
          <p className="text">
            <strong>Parabéns</strong>, com a sua atitude do bem
            você acaba de apoiar uma ONG que trabalha pelas crianças do Brasil. Pequenas atitudes podem transformar o mundo!
          </p>
          <div className="v-space"></div>
          <div className="feedback-picture">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mlw-firebase.appspot.com/o/assets%2Fselo-malwee-atitudes-do-bem.jpg?alt=media&token=09cfab7e-fb49-4ece-a21f-a164abeed6d9"
              alt="Clique e saiba mais no site da campanha"
            />
          </div>
          <div className="v-space-lg" />

          <p className="text is-500">
            Divulgue esse gesto e convide outras pessoas para participar desta corrente.
          </p>
          <div className="v-space" />

          <div className="link-share-container">

            <FacebookShareButton
              className="link-share"
              url={urlToShare}
              hashtag="AtitudesDoBem"
              quote="Eu fiz a diferença!"
              onShareWindowClose={onClickToClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <path id="iconfinder_facebook_circle_color_107175" d="M21.177,34.88h4.5V24h3l.4-3.749h-3.4l0-1.877c0-.977.093-1.5,1.5-1.5h1.875V13.12h-3c-3.6,0-4.871,1.819-4.871,4.878v2.251H18.931V24h2.246ZM24,44A20,20,0,1,1,44,24,20,20,0,0,1,24,44Z" transform="translate(-4 -4)" fill="#3a589b" fill-rule="evenodd"/>
              </svg>
            </FacebookShareButton>

            <LinkedinShareButton
              className="link-share"
              url={urlToShare}
              onShareWindowClose={onClickToClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <path id="iconfinder_linkedin_circle_color_107178" d="M35.225,33.425V25.617c0-4.183-2.233-6.13-5.211-6.13a4.492,4.492,0,0,0-4.079,2.249V19.807H21.408c.06,1.278,0,13.618,0,13.618h4.527V25.819a3.087,3.087,0,0,1,.149-1.1,2.476,2.476,0,0,1,2.321-1.655c1.639,0,2.293,1.249,2.293,3.079v7.286h4.526ZM16.639,17.948a2.36,2.36,0,1,0,.03-4.706,2.359,2.359,0,1,0-.059,4.706ZM24,44A20,20,0,1,1,44,24,20,20,0,0,1,24,44ZM18.9,33.425V19.807H14.377V33.425Z" transform="translate(-4 -4)" fill="#007ab9" fill-rule="evenodd"/>
              </svg>
            </LinkedinShareButton>

            <TwitterShareButton
              className="link-share"
              url={urlToShare}
              title="Eu fiz a diferença!"
              hashtags={['AtitudesDoBem']}
              onShareWindowClose={onClickToClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <path id="iconfinder_twitter_circle_color_107170" d="M26.778,16.189a4.044,4.044,0,0,0-2.724,4.067l.042.692-.7-.085a11.352,11.352,0,0,1-6.649-3.277l-.922-.918-.237.678a4.082,4.082,0,0,0,.866,4.181c.559.593.433.678-.531.325a1.659,1.659,0,0,0-.657-.155,5.075,5.075,0,0,0,.5,1.893A4.513,4.513,0,0,0,17.684,25.4l.685.325-.81.014c-.782,0-.81.014-.726.311a4.226,4.226,0,0,0,2.612,2.317l.866.3-.754.452a7.834,7.834,0,0,1-3.744,1.045,4.1,4.1,0,0,0-1.146.113,10.673,10.673,0,0,0,2.7,1.243,11.787,11.787,0,0,0,9.164-1.045,12.281,12.281,0,0,0,4.652-5.481,14.568,14.568,0,0,0,.95-4.236c0-.65.042-.735.824-1.511a9.208,9.208,0,0,0,.978-1.087c.14-.269.125-.269-.587-.029-1.187.424-1.355.367-.768-.268a4.117,4.117,0,0,0,.95-1.511c0-.042-.209.028-.447.155a7.722,7.722,0,0,1-1.229.48l-.754.241-.685-.467a5.678,5.678,0,0,0-1.187-.621A4.772,4.772,0,0,0,26.778,16.189ZM24,44A20,20,0,1,1,44,24,20,20,0,0,1,24,44Z" transform="translate(-4 -4)" fill="#598dca" fill-rule="evenodd"/>
              </svg>
            </TwitterShareButton>

            <WhatsappShareButton
              className="link-share"
              url={urlToShare}
              title="Eu fiz a diferença!"
              onShareWindowClose={onClickToClose}>
              <svg id="flat" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g id="whatsapp">
                  <path id="Caminho_245" data-name="Caminho 245" d="M20,40A20,20,0,1,0,0,20,20,20,0,0,0,20,40Z" fill="#57bb63" fill-rule="evenodd"/>
                  <path id="Caminho_246" data-name="Caminho 246" d="M26.014,38.456a12.421,12.421,0,0,1-5.994-1.531l-6.865,2.182,2.238-6.6a12.179,12.179,0,0,1-1.779-6.354,12.4,12.4,0,1,1,12.4,12.3Zm0-22.65A10.4,10.4,0,0,0,15.588,26.151a10.24,10.24,0,0,0,1.986,6.066l-1.3,3.842,4.006-1.273a10.373,10.373,0,1,0,5.736-18.979Zm6.262,13.179c-.076-.125-.279-.2-.582-.352s-1.8-.881-2.077-.981-.482-.151-.684.151-.785.981-.963,1.182-.354.227-.658.076a8.317,8.317,0,0,1-2.445-1.5,9.082,9.082,0,0,1-1.691-2.088c-.177-.3-.019-.465.133-.615.137-.135.3-.352.456-.528a2.038,2.038,0,0,0,.3-.5.551.551,0,0,0-.025-.529c-.076-.151-.684-1.635-.937-2.239s-.506-.5-.684-.5-.38-.025-.583-.025a1.122,1.122,0,0,0-.811.377,3.373,3.373,0,0,0-1.063,2.516,5.836,5.836,0,0,0,1.241,3.119A12.426,12.426,0,0,0,26.4,31.1c3.091,1.207,3.091.8,3.648.754A3.073,3.073,0,0,0,32.1,30.419a2.513,2.513,0,0,0,.177-1.435Z" transform="translate(-6.015 -6.015)" fill="#fff" fill-rule="evenodd"/>
                </g>
              </svg>
            </WhatsappShareButton>
          </div>

          <button
            className="button is-small"
            onClick={onClickToClose}>
            <span>Fechar</span>
          </button>

        </div>
      )}
    </>
  );
};

export default Feedbacks;