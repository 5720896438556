import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Barcode from '../components/partials/submit/Barcode';
import Form from '../components/partials/submit/Form';

import useModal from '../components/hooks/useModal';

import useSearchParam from '../components/hooks/useSearchParam';
import Feedbacks from '../components/partials/submit/Feedbacks';

const Submit = (props) => {

  const institutionId = useSearchParam('uid');
  
  const [code, setCode] = useState();
  const [codeStatus, setCodeStatus] = useState();
  
  const [formStatus, setFormStatus] = useState({});
  const [timestamp, setTimestamp] = useState(new Date());
  const [Modal, toggleModal] = useModal();
  
  const handleFormSubmit = ({ status, code }) => {
    setFormStatus({ status, code });
    if (status === 'success') setTimestamp(new Date());
    toggleModal();
  };

  const handleCodeChanges = (code, isValid) => {
    setCode(code);
    setCodeStatus(isValid);
  };

  return (

    <div className="is-inner-page">
      <Layout>

        <SEO title="Cadastro" />

        {!institutionId ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <b>ATENCÃO: Você precisa escolher uma ONG primeiro.</b>
          </div>
        ) : (
          <>
            <section
              className="section has-dots"
              style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
              <div className="container is-medium">
                <header className="section-header visible-desktop-true">
                  <h1 className="title is-xxlarge">Cadastro do código</h1>
                  <div className="v-space" />
                  <p className="text">
                    Procure na etiqueta das coleções Alto Verão e Celebration da Malwee, Malwee Kids e Malwee Liberta o código de 14 dígitos #atitudesdobem logo acima do código de barras. 
                  </p>

                  <div className="v-space-lg"></div>

                  <p className="text is-800">
                    Confira abaixo alguns exemplos de etiquetas
                  </p>
               
                  <figure
                    className="image tags-info"
                    style={{ backgroundImage: `url(${require('../images/pics/tags-info.png')})` }}>
                    <img src={require('../images/pics/tags-info.png')} alt="Image Placeholder" />
                  </figure>

                  <hr/>

                </header>
          

                <div className="columns">
                  <Barcode onChange={handleCodeChanges} timestamp={timestamp} />
                  <Form code={code} codeIsValid={codeStatus} institutionId={institutionId} onChangeStatus={handleFormSubmit} />
                </div>
              </div>
            </section>
            <Modal>
              <Feedbacks
                state={formStatus}
                onClickToClose={toggleModal}
              />
            </Modal>
          </>
        )}

      </Layout>
    </div>
  );
};

export default Submit;
