import Quagga from 'quagga';

let lastResult = null;

export default (onChange) => {
  Quagga.init({
    inputStream: {
      name: 'Live',
      type: 'LiveStream',
      target: document.querySelector('#interactive'),
    },
    decoder: {
      readers: [
        'code_128_reader',
        'ean_reader',
        // 'ean_8_reader',
        // 'code_39_reader',
        // 'code_39_vin_reader',
        // 'codabar_reader',
        // 'upc_reader',
        // 'upc_e_reader',
        // 'i2of5_reader',
        // '2of5_reader',
        // 'code_93_reader'
      ]
    }
  }, (error) => {
    if (error) {
      console.error(error);
      return;
    }
    Quagga.start();
    Quagga.onDetected((result) => {
      const code = result.codeResult.code;
      if (lastResult !== code) {
        lastResult = code;
        if (code.match('10000')) {
          onChange(code);
          Quagga.stop();
        }
        console.log(code);
      }
    });
  });

  return Quagga.stop;
};
