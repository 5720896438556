import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import readerScript from './readerScript';

const Reader = ({ onFinish }) => {

  useEffect(() => {

    const timeout = setTimeout;

    const stopReader = readerScript((code) => {
      timeout(() => onFinish(code), 800);
    });

    return () => {
      clearTimeout(timeout);
      stopReader();
    }

  }, []);

  return (
    <div className="scan-component">

      <div className="scan-component-info">
        <h3 className="title is-size-1 is-primary">
          Leitor de código
        </h3>
        <span className="text is-white">
          Escaneie o código de barras da campanha
        </span>
      </div>

      <button
        onClick={() => onFinish()}
        className="scan-validation-button">
        <span>
          Inserir manualmente
        </span>
      </button>

      <div id="interactive" className="viewport"></div>

    </div>
  );
};

Reader.propTypes = {
  onFinish: PropTypes.func
};

export default Reader;
